<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Tên danh sách" prop="name">
      <template slot-scope="scope">
        <span>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Mô tả" prop="name">
      <template slot-scope="scope">
        <span>{{ scope.row.description }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngày khởi tạo" prop="created_at">
      <template slot-scope="scope">
        <span>{{ scope.row.created_at }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngôn ngữ" width="250" align="center">
      <template slot-scope="scope">
        <el-row type="flex" align="middle" justify="center">
          <img
            v-for="locale in locales"
            :key="locale.code"
            class="language"
            :class="{ inactive: !scope.row.translations || !scope.row.translations.find((t) => t.locale === locale.code)}"
            :src="require(`@/assets/images/${locale.code}.png`)"
            alt="href"
          />
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="Thao tác" width="150" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Chỉnh sửa" placement="top">
          <el-button icon="el-icon-edit" type="primary" circle size="small" @click="handleUpdate(scope.row)"></el-button>
        </el-tooltip>
        <el-tooltip content="Chi tiết" placement="top">
          <el-button icon="el-icon-document" type="primary" circle size="small" @click="$router.push({ name: 'BoardsDetail', params: { id: scope.row.id } })"></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button icon="el-icon-delete" type="danger" circle size="small" @click="handleDelete(scope.row)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteTag } from '@/services/tag'
export default {
  name: 'ListTags',
  props: {
    data: Array,
    loading: Boolean
  },
  computed: {
    ...mapGetters(['constant']),
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
    handleDelete(row) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        deleteTag(row.id).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: `Xóa thành công`
          })
          this.$emit('reload')
        })
      })
    },

    handleUpdate(row) {
      this.$emit('update', row)
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin: 5px 0;
  &.language {
    width: 28px;
    height: 28px;
    border-radius: 0px;
    margin: 0 5px;
    &.inactive {
      filter: grayscale(100%);
    }
  }
}
</style>
