<template>
  <div class="tag-tab shared-index">
    <el-row class="table-title" type="flex" align="center">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane name="boards">
            <div slot="label">
              Danh sách yêu thích
              <span>({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="createBoard"
        >Thêm</el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="handleChangeTab"
        >Làm mới</el-button>
      </div>
    </el-row>
    <list-boards
      :loading="callingAPI"
      :data="allBoards"
      @reload="handleReloadAfterDelete"
      @update="updateBoard"
    ></list-boards>
    <el-row type="flex" justify="end" class="my-4">
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :total="total"
        background
        layout="total, prev, pager, next"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <update-board-popup
      v-if="visibleUpdate"
      :board="selectedBoard"
      :visible="visibleUpdate"
      @close="visibleUpdate = false"
      @reload="loadData"
    ></update-board-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getBoards } from '@/services/board'
import ListBoards from './components/List.vue'
import UpdateBoardPopup from './components/popup/Update.vue'

import { getTranslation } from '@/utils/filters'

export default {
  name: 'Boards',
  components: {
    ListBoards,
    UpdateBoardPopup
  },
  data() {
    return {
      visibleUpdate: false,
      callingAPI: false,
      allBoards: [],
      selectedBoard: {},
      activeTab: 'boards',
      total: 0,
      limit: 10,
      page: 1,
      searchText: ''
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        page: this.page,
        per_page: this.limit,
        locale: this.language
      }
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    ...mapActions('public', ['updateReloadConstant']),

    loadData() {
      this.callingAPI = true
      this.visibleUpdate = false
      getBoards(this.params).then((response) => {
        this.allBoards = response.result.map((r) => ({
          ...r,
          name: getTranslation(r, this.language) ? getTranslation(r, this.language).name : '',
          description: getTranslation(r, this.language) ? getTranslation(r, this.language).description : ''
        }))
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    },
    createBoard() {
      this.selectedBoard = null
      this.visibleUpdate = true
    },
    updateBoard(data) {
      this.selectedBoard = data
      this.visibleUpdate = true
    },
    handleChangeTab() {
      this.page = 1
      this.loadData()
    },
    handleReloadAfterDelete() {
      if (this.allBoards.length === 1 && this.page > 1) {
        this.page = this.page - 1
      }
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
